import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useAuth } from '../contexts/AuthContext';
import ProtectedSubmit from '../components/ProtectedSubmit';
import { Link } from 'react-router-dom';
import ReactMarkdown from 'react-markdown';

function CoverLetterWriter() {
  const { user } = useAuth();
  const [title, setTitle] = useState('');
  const [jobDescription, setJobDescription] = useState('');
  const [generatedContent, setGeneratedContent] = useState('');
  const [error, setError] = useState('');
  const [pastGenerations, setPastGenerations] = useState([]);
  const [resume, setResume] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [visibleLetters, setVisibleLetters] = useState({});
  const [successMessage, setSuccessMessage] = useState('');
  const [isGenerating, setIsGenerating] = useState(false);

  useEffect(() => {
    fetchPastGenerations();
    fetchResume();
  }, []);

  const fetchPastGenerations = async () => {
    try {
      const response = await axios.get('/api/past-cover-letters', { withCredentials: true });
      setPastGenerations(response.data.pastGenerations);
    } catch (error) {
      console.error('Error fetching past generations:', error);
    }
  };

  const fetchResume = async () => {
    try {
      const response = await axios.get('/api/user-resume', { withCredentials: true });
      if (response.data.resume) {
        setResume(response.data.fileName);
      }
    } catch (error) {
      console.error('Error fetching resume:', error);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');
    setGeneratedContent('');
    setIsGenerating(true);

    if (!title) {
      setError('Please provide a title for this cover letter.');
      setIsGenerating(false);
      return;
    }

    try {
      const response = await axios.post('/api/generate-cover-letter',
        {
          title,
          jobDescription,
          additionalInfo: user?.additionalInfo,
          challengesSolved: user?.challengesSolved
        },
        { withCredentials: true }
      );

      setGeneratedContent(response.data.generatedContent);
      fetchPastGenerations();
    } catch (error) {
      if (error.response && error.response.status === 403) {
        setError('You have reached your usage limit for cover letters. Please upgrade your plan.');
      } else {
        setError('An error occurred while generating the cover letter. Please try again.');
      }
    } finally {
      setIsGenerating(false);
    }
  };

  const copyToClipboard = (text) => {
    navigator.clipboard.writeText(text).then(() => {
      setSuccessMessage('Cover letter copied to clipboard!');
    });
  };

  const toggleLetterVisibility = (index) => {
    setVisibleLetters(prev => ({
      ...prev,
      [index]: !prev[index]
    }));
  };

  const filteredGenerations = pastGenerations.filter(generation =>
    generation.title.toLowerCase().includes(searchTerm.toLowerCase()) ||
    generation.file_content.toLowerCase().includes(searchTerm.toLowerCase())
  ).sort((a, b) => new Date(b.created_at) - new Date(a.created_at));

  return (
    <div className="max-w-7xl mx-auto py-6 sm:px-6 lg:px-8">
      <div className="px-4 py-6 sm:px-0">
        <h1 className="text-3xl font-bold mb-6">Cover Letter Generator</h1>

        <div className="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4">
          <form onSubmit={handleSubmit} className="space-y-6">
            <div>
              <label htmlFor="title" className="block text-sm font-medium text-gray-700">
                Cover Letter Title
              </label>
              <input
                type="text"
                id="title"
                value={title}
                onChange={(e) => setTitle(e.target.value)}
                className="mt-1 block w-full rounded-md border border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm p-3"
                placeholder="e.g., Software Engineer - Google Application"
                required
              />
            </div>

            <div>
              <label htmlFor="jobDescription" className="block text-sm font-medium text-gray-700">
                Job Description
              </label>
              <textarea
                id="jobDescription"
                value={jobDescription}
                onChange={(e) => setJobDescription(e.target.value)}
                className="mt-1 block w-full rounded-md border border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm p-3"
                placeholder="Paste the job description here..."
                rows="10"
                style={{ resize: 'vertical' }}
                required
              />
            </div>

            {user && (
              <div>
                <label className="block text-sm font-medium text-gray-700">
                  Resume
                </label>
                <div className="mt-1">
                  {resume ? (
                    <div className="text-sm text-gray-600">
                      {resume}
                      <Link
                        to="/profile"
                        className="ml-2 text-indigo-600 hover:text-indigo-800"
                      >
                        Update Resume
                      </Link>
                    </div>
                  ) : (
                    <div className="text-sm text-red-600">
                      Please upload a resume in your profile first
                      <Link
                        to="/profile"
                        className="ml-2 text-indigo-600 hover:text-indigo-800"
                      >
                        Go to Profile
                      </Link>
                    </div>
                  )}
                </div>
              </div>
            )}
            <div>
              <ProtectedSubmit
                onSubmit={handleSubmit}
                buttonText="Generate Cover Letter"
                isLoading={isGenerating}
              />
            </div>
          </form>

          {error && (
            <div className="mt-4 text-red-600">{error}</div>
          )}
        </div>

        {generatedContent && (
          <div className="mt-8">
            <h2 className="text-2xl font-semibold text-gray-900 mb-4">Generated Cover Letter</h2>
            <div className="bg-white shadow overflow-hidden sm:rounded-lg">
              <div className="px-4 py-5 sm:p-6">
                <ReactMarkdown className="text-sm text-gray-700 prose max-w-none">
                  {generatedContent}
                </ReactMarkdown>
              </div>
              <div className="px-4 py-3 bg-gray-50 text-right sm:px-6">
                <button
                  onClick={() => copyToClipboard(generatedContent)}
                  className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                >
                  Copy to Clipboard
                </button>
              </div>
            </div>
          </div>
        )}

        {pastGenerations.length > 0 && (
          <div className="mt-12">
            <h2 className="text-2xl font-semibold text-gray-900 mb-4">Past Cover Letters</h2>

            <div className="mb-4">
              <input
                type="text"
                placeholder="Search past cover letters..."
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                className="w-full rounded-md border border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm p-3"
              />
            </div>

            <div className="space-y-6">
              {filteredGenerations.map((generation, index) => (
                <div key={index} className="bg-white shadow overflow-hidden sm:rounded-lg">
                  <div
                    className="px-4 py-5 sm:px-6 cursor-pointer hover:bg-gray-50"
                    onClick={() => toggleLetterVisibility(index)}
                  >
                    <div className="flex justify-between items-center">
                      <div>
                        <h3 className="text-lg leading-6 font-medium text-gray-900">
                          {generation.title}
                        </h3>
                        <p className="mt-1 max-w-2xl text-sm text-gray-500">
                          Generated on {new Date(generation.created_at).toLocaleDateString()}
                        </p>
                      </div>
                      <span className="text-gray-400">
                        {visibleLetters[index] ? '▼' : '▶'}
                      </span>
                    </div>
                  </div>

                  {visibleLetters[index] && (
                    <div className="border-t border-gray-200 px-4 py-5 sm:p-6">
                      <pre className="text-sm text-gray-700 whitespace-pre-wrap">
                        {generation.file_content}
                      </pre>
                      <div className="mt-4 text-right">
                        <button
                          onClick={() => copyToClipboard(generation.file_content)}
                          className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                        >
                          Copy to Clipboard
                        </button>
                        {/* {successMessage && <div className="mt-2 text-green-600">{successMessage}</div>} */}
                      </div>
                    </div>
                  )}
                </div>
              ))}
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default CoverLetterWriter;