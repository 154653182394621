import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';
import { useStripe } from '../contexts/StripeContext';
import PlanUsage from '../components/PlanUsage';
import axios from 'axios';
import { validatePassword, PasswordRequirements } from '../utils/passwordValidation';

function Profile() {
  const { user, isAuthenticated } = useAuth();
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  const [challengesSolved, setChallengesSolved] = useState('');
  const [additionalInfo, setAdditionalInfo] = useState('');
  const { cancelSubscription, loading: stripeLoading } = useStripe();
  const [subscriptionStatus, setSubscriptionStatus] = useState(null);
  const [resume, setResume] = useState(null);
  const [resumeFileName, setResumeFileName] = useState('');
  const [redeemCode, setRedeemCode] = useState('');
  const [codeError, setCodeError] = useState('');
  const [codeSuccess, setCodeSuccess] = useState('');
  const [currentPassword, setCurrentPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [nameError, setNameError] = useState('');
  const [nameSuccess, setNameSuccess] = useState('');
  const [passwordError, setPasswordError] = useState('');
  const [passwordSuccess, setPasswordSuccess] = useState('');

  useEffect(() => {
    const fetchUserData = async () => {
      if (user) {
        setName(user.name || '');
        setEmail(user.email || '');
        setChallengesSolved(user.challengesSolved || '');
        setAdditionalInfo(user.additionalInfo || '');

        try {
          const response = await axios.get('/api/user-resume', { withCredentials: true });
          if (response.data.resume) {
            setResume(response.data.resume);
            setResumeFileName(response.data.fileName || 'Resume uploaded');
          }
        } catch (error) {
          console.error('Error fetching resume:', error);
        }
      }
    };

    fetchUserData();
  }, [user]);

  useEffect(() => {
    const fetchSubscriptionStatus = async () => {
      try {
        const response = await fetch('/api/subscription-status');
        const data = await response.json();
        setSubscriptionStatus(data);
      } catch (error) {
        console.error('Error fetching subscription status:', error);
      }
    };

    fetchSubscriptionStatus();
  }, []);

  const handleBasicInfoSubmit = async (e) => {
    e.preventDefault();
    setNameError('');
    setNameSuccess('');

    if (!name.trim()) {
      setNameError('Name cannot be empty');
      return;
    }

    try {
      const response = await axios.put('/api/update-profile',
        { name },
        { withCredentials: true }
      );
      
      if (response.data.message === 'Profile updated successfully') {
        setNameSuccess('Profile updated successfully');
      }
    } catch (error) {
      if (error.response && error.response.data.message) {
        setNameError(error.response.data.message);
      } else {
        setNameError('An error occurred while updating profile');
      }
    }
  };

  const handlePasswordChange = async (e) => {
    e.preventDefault();
    setPasswordError('');
    setPasswordSuccess('');

    if (!currentPassword || !newPassword) {
      setPasswordError('Both current and new password are required');
      return;
    }

    const { isValid, messages } = validatePassword(newPassword);
    if (!isValid) {
      setPasswordError(messages.join('\n'));
      return;
    }

    try {
      const response = await axios.put('/api/update-password',
        {
          currentPassword,
          newPassword
        },
        { withCredentials: true }
      );
      
      if (response.data.message === 'Password updated successfully') {
        setPasswordSuccess('Password updated successfully');
        setCurrentPassword('');
        setNewPassword('');
      }
    } catch (error) {
      if (error.response && error.response.data.message) {
        setPasswordError(error.response.data.message);
      } else {
        setPasswordError('An error occurred while updating password');
      }
    }
  };

  const handleAdditionalInfoSubmit = async (e) => {
    e.preventDefault();
    setError('');
    setSuccess('');

    try {
      const response = await axios.put('/api/update-additional-info',
        {
          challengesSolved,
          additionalInfo
        },
        { withCredentials: true }
      );
      if (response.data.message === 'Profile updated successfully') {
        setSuccess('Additional information updated successfully');
      }
    } catch (error) {
      if (error.response && error.response.data.message) {
        setError(error.response.data.message);
      } else {
        setError('An error occurred while updating additional information');
      }
    }
  };

  const handleCancelSubscription = async () => {
    if (window.confirm('Are you sure you want to cancel your subscription?')) {
      const result = await cancelSubscription();
      if (result.success) {
        // Refresh subscription status
        window.location.reload();
      }
    }
  };

  const handleResumeUpload = async (event) => {
    const file = event.target.files[0];
    if (file) {
      try {
        const formData = new FormData();
        formData.append('resume', file);

        const response = await axios.post('/api/upload-resume', formData, {
          headers: {
            'Content-Type': 'multipart/form-data'
          },
          withCredentials: true
        });

        if (response.data.success) {
          setResume(response.data.resume);
          setResumeFileName(file.name);
          setSuccess('Resume uploaded successfully');
        }
      } catch (error) {
        setError('Error uploading resume');
      }
    }
  };

  const handleCodeRedeem = async (e) => {
    e.preventDefault();
    setCodeError('');
    setCodeSuccess('');

    try {
      const response = await axios.post('/api/redeem-code', 
        { code: redeemCode },
        { withCredentials: true }
      );
      
      setCodeSuccess(`Successfully redeemed code for ${response.data.planType} plan!`);
      setRedeemCode('');
      // Refresh subscription status
      window.location.reload();
    } catch (error) {
      setCodeError(error.response?.data?.message || 'Error redeeming code');
    }
  };

  const redeemCodeForm = (
    <form className="mt-8 space-y-6" onSubmit={handleCodeRedeem}>
      <h3 className="text-xl font-medium text-gray-900">Redeem Code</h3>
      <div className="rounded-md shadow-sm -space-y-px">
        <div className="flex">
          <input
            type="text"
            className="appearance-none rounded-l-md relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
            placeholder="Enter subscription code"
            value={redeemCode}
            onChange={(e) => setRedeemCode(e.target.value)}
          />
          <button
            type="submit"
            className="relative inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-r-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
          >
            Redeem
          </button>
        </div>
      </div>
      {codeError && <div className="text-red-500 text-sm">{codeError}</div>}
      {codeSuccess && <div className="text-green-500 text-sm">{codeSuccess}</div>}
    </form>
  );

  if (!isAuthenticated) {
    return <div className='max-w-7xl mx-auto py-6 sm:px-6 lg:px-8'>Please log in to view your profile.</div>;
  }

  return (
    <div className="min-h-full flex flex-col items-center py-12 px-4 sm:px-6 lg:px-8">
      <div className="max-w-md w-full space-y-8">
        <h2 className="text-center text-3xl font-extrabold text-gray-900">Profile Settings</h2>

        {/* Basic Information Form */}
        <form onSubmit={handleBasicInfoSubmit} className="mt-8 bg-white p-6 rounded-lg shadow">
          <h3 className="text-xl font-medium text-gray-900 mb-6">Basic Information</h3>
          <div className="space-y-4">
            <div>
              <label htmlFor="name" className="block text-sm font-medium text-gray-700 mb-1">
                Full Name
              </label>
              <input
                id="name"
                name="name"
                type="text"
                required
                className="appearance-none rounded-md relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                placeholder="Your full name"
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
            </div>
            <div>
              <label htmlFor="email-display" className="block text-sm font-medium text-gray-700 mb-1">
                Email Address
              </label>
              <input
                id="email-display"
                name="email"
                type="email"
                className="appearance-none rounded-md relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm bg-gray-100"
                value={email}
                disabled
              />
              <p className="mt-1 text-sm text-gray-500">
                Email address cannot be changed. Please{' '}
                <Link to="/contact" className="text-indigo-600 hover:text-indigo-500 font-medium">
                  contact support
                </Link>
                {' '}if you need to change your email.
              </p>
            </div>
            {nameError && <div className="text-red-500 text-sm">{nameError}</div>}
            {nameSuccess && <div className="text-green-500 text-sm">{nameSuccess}</div>}
            <button
              type="submit"
              className="mt-4 w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
            >
              Update Name
            </button>
          </div>
        </form>

        {/* Password Change Form */}
        <form onSubmit={handlePasswordChange} className="mt-8 bg-white p-6 rounded-lg shadow">
          <h3 className="text-xl font-medium text-gray-900 mb-6">Change Password</h3>
          <div className="space-y-4">
            <div>
              <label htmlFor="current-password" className="block text-sm font-medium text-gray-700 mb-1">
                Current Password
              </label>
              <input
                id="current-password"
                name="current-password"
                type="password"
                className="appearance-none rounded-md relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                placeholder="Enter current password"
                value={currentPassword}
                onChange={(e) => setCurrentPassword(e.target.value)}
              />
            </div>
            <div>
              <label htmlFor="new-password" className="block text-sm font-medium text-gray-700 mb-1">
                New Password
              </label>
              <input
                id="new-password"
                name="new-password"
                type="password"
                className="appearance-none rounded-md relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                placeholder="Enter new password"
                value={newPassword}
                onChange={(e) => setNewPassword(e.target.value)}
              />
              <PasswordRequirements />
            </div>
            {passwordError && <div className="text-red-500 text-sm whitespace-pre-line">{passwordError}</div>}
            {passwordSuccess && <div className="text-green-500 text-sm">{passwordSuccess}</div>}
            <button
              type="submit"
              className="mt-4 w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
            >
              Update Password
            </button>
          </div>
        </form>

        {subscriptionStatus && subscriptionStatus.plan_type !== 'free' && subscriptionStatus.status !== 'canceled' && (
          <div className="mt-8 bg-white shadow sm:rounded-lg">
            <div className="px-4 py-5 sm:p-6">
              <h3 className="text-lg leading-6 font-medium text-gray-900">
                Subscription Status
              </h3>
              <div className="mt-2 max-w-xl text-sm text-gray-500">
                <p>You are currently on the {subscriptionStatus.plan_type} plan.</p>
                <p>Status: {subscriptionStatus.status}</p>
              </div>
              <div className="mt-5">
                <button
                  type="button"
                  onClick={handleCancelSubscription}
                  disabled={stripeLoading}
                  className="inline-flex items-center justify-center px-4 py-2 border border-transparent font-medium rounded-md text-red-700 bg-red-100 hover:bg-red-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:text-sm"
                >
                  {stripeLoading ? 'Processing...' : 'Cancel Subscription'}
                </button>
              </div>
            </div>
          </div>
        )}

        {(!subscriptionStatus || subscriptionStatus.plan_type === 'free') && redeemCodeForm}

        <PlanUsage />

        <div className="mt-4">
          <label className="block text-sm font-medium text-gray-700">
            Resume
          </label>
          <div className="mt-1 flex items-center">
            {resumeFileName ? (
              <span className="mr-2 text-sm text-gray-600">{resumeFileName}</span>
            ) : (
              <span className="mr-2 text-sm text-gray-600">No resume uploaded</span>
            )}
            <label className="cursor-pointer bg-white py-2 px-3 border border-gray-300 rounded-md shadow-sm text-sm leading-4 font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
              <span>{resumeFileName ? 'Change Resume' : 'Upload Resume'}</span>
              <input
                type="file"
                className="sr-only"
                accept=".txt,.pdf,.doc,.docx"
                onChange={handleResumeUpload}
              />
            </label>
          </div>
        </div>

        {/* Additional Information Form */}
        <form className="mt-8 space-y-6" onSubmit={handleAdditionalInfoSubmit}>
          <h3 className="text-xl font-medium text-gray-900">Additional Information</h3>
          {/* Additional info textarea */}
          <div className="mt-4">
            <label htmlFor="additionalInfo" className="block text-sm font-medium text-gray-700">
              Additional Information (this will help us generate better results)
            </label>
            <textarea
              id="additionalInfo"
              name="additionalInfo"
              rows={4}
              className="mt-1 appearance-none rounded-md relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
              placeholder="Add any other relevant information about yourself"
              value={additionalInfo}
              onChange={(e) => setAdditionalInfo(e.target.value)}
            />
          </div>

          {error && <div className="text-red-500 text-sm">{error}</div>}
          {success && <div className="text-green-500 text-sm">{success}</div>}

          <div>
            <button type="submit" className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
              Update Additional Information
            </button>
          </div>

        </form>
      </div>
    </div>
  );
}

export default Profile;