const config = {
  apiUrl: process.env.REACT_APP_API_URL,
  stripeKey: process.env.REACT_APP_STRIPE_PUBLIC_KEY,
  isDevelopment: process.env.REACT_APP_ENV === 'development',
  recaptchaSiteKey: process.env.REACT_APP_RECAPTCHA_SITE_KEY,
  stripePrices: {
    basic: process.env.REACT_APP_STRIPE_PRICE_BASIC,
    premium: process.env.REACT_APP_STRIPE_PRICE_PREMIUM,
    headshot: process.env.REACT_APP_STRIPE_PRICE_HEADSHOT,
  },
};

export default config; 