export const PasswordRequirements = () => (
    <div className="mt-1 text-sm text-gray-500">
      Password must:
      <ul className="list-disc list-inside">
        <li>Be at least 8 characters long</li>
        <li>Contain at least one uppercase letter</li>
        <li>Contain at least one lowercase letter</li>
        <li>Contain at least one number</li>
        <li>Contain at least one special character (!@#$%^&*)</li>
      </ul>
    </div>
  );


export const validatePassword = (password) => {
  const requirements = {
    length: password.length >= 8,
    uppercase: /[A-Z]/.test(password),
    lowercase: /[a-z]/.test(password),
    number: /[0-9]/.test(password),
    special: /[!@#$%^&*]/.test(password),
  };

  const messages = [];
  if (!requirements.length) messages.push('Password must be at least 8 characters long');
  if (!requirements.uppercase) messages.push('Password must contain at least one uppercase letter');
  if (!requirements.lowercase) messages.push('Password must contain at least one lowercase letter');
  if (!requirements.number) messages.push('Password must contain at least one number');
  if (!requirements.special) messages.push('Password must contain at least one special character (!@#$%^&*)');

  return {
    isValid: Object.values(requirements).every(Boolean),
    messages
  };
}; 