import { useEffect, useState } from 'react';
import { useAuth } from '../contexts/AuthContext';

function PlanUsage() {
  const { user } = useAuth();
  const [usageData, setUsageData] = useState(null);
  const [planDetails, setPlanDetails] = useState(null);

  useEffect(() => {
    const fetchUsageData = async () => {
      try {
        const response = await fetch('/api/user/usage', {
          headers: {
            'Authorization': `Bearer ${user.token}`
          }
        });
        const data = await response.json();
        setUsageData(data.usage);
        setPlanDetails(data.subscription);
      } catch (error) {
        console.error('Error fetching usage data:', error);
      }
    };

    if (user) {
      fetchUsageData();
    }
  }, [user]);

  if (!usageData || !planDetails) {
    return <div>Loading usage data...</div>;
  }

  const formatDate = (dateString) => {
    return new Date(dateString).toLocaleDateString('en-US', {
      year: 'numeric',
      month: 'long',
      day: 'numeric'
    });
  };

  const getUsagePercentage = (used, limit) => {
    return Math.round((used / limit) * 100);
  };

  return (
    <div className="bg-white shadow rounded-lg p-6">
      <h2 className="text-xl font-semibold mb-4">Your Plan Usage</h2>
      
      <div className="mb-6">
        <p className="text-gray-600">
          Plan: <span className="font-medium">{planDetails.plan_type.charAt(0).toUpperCase() + planDetails.plan_type.slice(1)}</span>
        </p>
        {planDetails.renewal_date && (
          <p className="text-gray-600">
            Renews: <span className="font-medium">{formatDate(planDetails.renewal_date)}</span>
          </p>
        )}
        {planDetails.status === 'canceled' && (
          <p className="text-red-600">
            This subscription has been canceled. You will still see you have the {planDetails.plan_type.charAt(0).toUpperCase() + planDetails.plan_type.slice(1)} plan and can use your remaining credits.
          </p>
        )}
      </div>

      <div className="space-y-4">
        <div>
          <div className="flex justify-between mb-1">
            <span className="text-sm font-medium text-gray-700">Headshot Credits</span>
            <span className="text-sm text-gray-600">
              {usageData.headshot_generations} / {usageData.headshot_credits}
            </span>
          </div>
          <div className="w-full bg-gray-200 rounded-full h-2">
            <div 
              className="bg-blue-600 h-2 rounded-full max-w-full" 
              style={{ width: `${getUsagePercentage(usageData.headshot_generations, usageData.headshot_credits)}%` }}
            ></div>
          </div>
          <p className="text-xs text-gray-500 mt-1">
            {usageData.headshot_credits - usageData.headshot_generations} credits remaining
          </p>
        </div>

        <div>
          <div className="flex justify-between mb-1">
            <span className="text-sm font-medium text-gray-700">Cover Letters</span>
            <span className="text-sm text-gray-600">
              {usageData.cover_letter_generations} / {planDetails.limits.cover_letter_generations}
            </span>
          </div>
          <div className="w-full bg-gray-200 rounded-full h-2">
            <div 
              className="bg-green-600 h-2 rounded-full max-w-full" 
              style={{ width: `${getUsagePercentage(usageData.cover_letter_generations, planDetails.limits.cover_letter_generations)}%` }}
            ></div>
          </div>
        </div>

        <div>
          <div className="flex justify-between mb-1">
            <span className="text-sm font-medium text-gray-700">Interview Questions</span>
            <span className="text-sm text-gray-600">
              {usageData.interview_questions_generations} / {planDetails.limits.interview_questions_generations}
            </span>
          </div>
          <div className="w-full bg-gray-200 rounded-full h-2">
            <div 
              className="bg-purple-600 h-2 rounded-full max-w-full" 
              style={{ width: `${getUsagePercentage(usageData.interview_questions_generations, planDetails.limits.interview_questions_generations)}%` }}
            ></div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PlanUsage; 