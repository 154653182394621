import React, { useState, useCallback, useEffect } from 'react';
import { useDropzone } from 'react-dropzone';
import axios from 'axios';
import { useAuth } from '../contexts/AuthContext';
import { FaImages, FaMale, FaFemale, FaDownload } from 'react-icons/fa';
import ProtectedSubmit from '../components/ProtectedSubmit';
import { format, addMonths } from 'date-fns';
import { useStripe } from '../contexts/StripeContext';
import config from '../config';

function HeadshotGenerator() {
  const { user } = useAuth();
  const [files, setFiles] = useState([]);
  const [generating, setGenerating] = useState(false);
  const [error, setError] = useState('');
  const [modelId, setModelId] = useState(null);
  const [type, setType] = useState('');
  const [generatedHeadshots, setGeneratedHeadshots] = useState([]);
  const [hasPurchased, setHasPurchased] = useState(false);
  const { redirectToCheckout, loading, stripeError } = useStripe();

  const MAX_FILE_SIZE = 10 * 1024 * 1024; // 10MB in bytes

  const onDrop = useCallback(acceptedFiles => {
    // Check each file's size before adding
    const validFiles = [];
    const oversizedFiles = [];

    acceptedFiles.forEach(file => {
      if (file.size <= MAX_FILE_SIZE) {
        validFiles.push(file);
      } else {
        oversizedFiles.push(file.name);
      }
    });

    // If any files were too large, show error
    if (oversizedFiles.length > 0) {
      setError(`The following files exceed the 10MB size limit:\n${oversizedFiles.join('\n')}\n\nPlease compress these images and try again.`);
      // Still add valid files if any
      if (validFiles.length > 0) {
        setFiles(prevFiles => [...prevFiles, ...validFiles]);
      }
    } else {
      setFiles(prevFiles => [...prevFiles, ...validFiles]);
    }
  }, []);

  const removeFile = (indexToRemove) => {
    setFiles(files.filter((_, index) => index !== indexToRemove));
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: {
      'image/png': ['.png'],
      'image/jpeg': ['.jpg', '.jpeg']
    },
    maxFiles: 10
  });

  useEffect(() => {
    const fetchHeadshots = async () => {
      try {
        const response = await axios.get('/api/headshots');
        setGeneratedHeadshots(response.data.imageUrls);
      } catch (err) {
        console.error('Error fetching headshots:', err);
      }
    };
    fetchHeadshots();
  }, []);

  const handleGenerate = async () => {
    try {
      if (!type) {
        setError('Please select your gender for optimal model training');
        return;
      }

      if (files.length < 4 || files.length > 10) {
        setError('Please upload between 4 and 10 photos');
        return;
      }

      setGenerating(true);
      setError('');

      // Create FormData and append all files and type
      const formData = new FormData();
      files.forEach(file => {
        formData.append('images', file);
      });
      formData.append('type', type);

      // Generate headshots
      const response = await axios.post('/api/generate-headshot', formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      });

      setModelId(response.data.modelId);
      alert('Your headshots are being generated! You will receive an email when they are ready (approximately 20 minutes). You can also check back here to view your photos once they are complete.');
      
    } catch (err) {
      setError(err.response?.data?.message || 'Error generating headshots');
    } finally {
      setGenerating(false);
    }
  };

  const handleDownload = async (url, index) => {
    try {
      const response = await axios.get(url, { responseType: 'blob' });
      const blob = new Blob([response.data]);
      const downloadUrl = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = downloadUrl;
      link.download = `headshot_${index + 1}.jpg`;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      window.URL.revokeObjectURL(downloadUrl);
    } catch (err) {
      console.error('Error downloading image:', err);
    }
  };

  return (
    <div className="container mx-auto px-4 py-8 max-w-4xl">
      <div className="space-y-8">
        <div className="text-center space-y-4">
          <h1 className="text-3xl font-bold">Professional Headshot Generator</h1>
          <p className="text-gray-600">
            Generate professional AI headshots in about 20 minutes. You'll receive an email when they're ready!
          </p>
        </div>

        {!hasPurchased && (
          <div className="bg-white shadow sm:rounded-lg mb-8">
            <div className="px-4 py-5 sm:p-6">
              <h3 className="text-lg leading-6 font-medium text-gray-900">
                Generate Professional Headshots
              </h3>
              <div className="mt-2 max-w-xl text-sm text-gray-500">
                <p>Purchase a one-time package to generate ~16 (amount may vary) professional AI headshots.</p>
              </div>
              <div className="mt-5">
                <ProtectedSubmit
                  onSubmit={() => redirectToCheckout(config.stripePrices.headshot, true)}
                  disabled={loading}
                  className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-indigo-600 hover:bg-indigo-700"
                  buttonText="Purchase for $9.99"
                >
                  Purchase for $9.99
                </ProtectedSubmit>
                {/* <button
                  type="button"
                  onClick={() => redirectToCheckout(config.stripePrices.headshot, true)}
                  className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-indigo-600 hover:bg-indigo-700"
                >
                  Purchase for $9.99
                </button> */}
              </div>
            </div>
          </div>
        )}

        {/* Gender Selection */}
        <div className="space-y-4">
          <label className="block text-lg font-medium">Select Your Gender</label>
          <p className="text-sm text-gray-600">
            This helps our AI model generate more accurate results. 
            Please select the option that will produce results most aligned with your desired appearance.
          </p>
          <div className="grid grid-cols-2 gap-4">
            <button
              onClick={() => setType('man')}
              className={`p-4 border-2 rounded-lg flex flex-col items-center space-y-2 ${
                type === 'man' ? 'border-blue-500 bg-blue-50' : 'border-gray-200'
              }`}
            >
              <FaMale className="h-8 w-8" />
              <span>Man</span>
            </button>
            <button
              onClick={() => setType('woman')}
              className={`p-4 border-2 rounded-lg flex flex-col items-center space-y-2 ${
                type === 'woman' ? 'border-blue-500 bg-blue-50' : 'border-gray-200'
              }`}
            >
              <FaFemale className="h-8 w-8" />
              <span>Woman</span>
            </button>
          </div>
        </div>

        {/* Image Upload */}
        <div className="space-y-4">
          <label className="block text-lg font-medium">Upload Your Photos</label>
          <div
            {...getRootProps()}
            className="border-2 border-dashed p-8 rounded-lg text-center cursor-pointer hover:border-blue-500 transition-colors"
          >
            <input {...getInputProps()} />
            <FaImages className="mx-auto h-12 w-12 text-gray-400 mb-4" />
            {isDragActive ? (
              <p>Drop your photos here...</p>
            ) : (
              <div className="space-y-2">
                <p>Upload 4-10 high-quality selfies</p>
                <p className="text-sm text-gray-500">
                  Front facing, one person in frame, no glasses or hats
                </p>
                <p className="text-sm text-gray-500">
                  Maximum file size: 10MB per image
                </p>
              </div>
            )}
          </div>
        </div>

        {/* Preview Images */}
        {files.length > 0 && (
          <div className="space-y-4">
            <h2 className="text-lg font-medium">Selected Photos</h2>
            <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4">
              {files.map((file, index) => (
                <div key={index} className="relative">
                  <img
                    src={URL.createObjectURL(file)}
                    alt={`Upload ${index + 1}`}
                    className="w-full h-40 object-cover rounded-lg"
                  />
                  <button
                    onClick={() => removeFile(index)}
                    className="absolute -top-3 -right-3 bg-red-500 text-white rounded-full w-8 h-8 flex items-center justify-center hover:bg-red-600 text-xl leading-none font-medium pb-0.5"
                    title="Remove image"
                  >
                    ×
                  </button>
                </div>
              ))}
            </div>
          </div>
        )}

        <ProtectedSubmit
          onSubmit={handleGenerate}
          disabled={generating || files.length < 4 || !type}
        >
          {generating ? 'Generating...' : 'Generate Headshots'}
        </ProtectedSubmit>

        {error && (
          <p className="text-red-500 text-center">{error}</p>
        )}

        {/* Add Generated Headshots Section */}
        {generatedHeadshots.length > 0 && (
          <div className="space-y-4 mt-8" id="generated-headshots">
            <h2 className="text-2xl font-bold">Your Generated Headshots</h2>
            <div className="grid grid-cols-2 md:grid-cols-3 gap-4">
              {generatedHeadshots.map((headshot, index) => (
                <div key={index} className="space-y-2">
                  <div className="relative">
                    <a href={headshot.file_url} target="_blank" rel="noopener noreferrer">
                    <img
                      src={headshot.file_url}
                      alt={`Generated Headshot ${index + 1}`}
                      className="w-full h-64 object-cover rounded-lg"
                    /></a>
                    {/* <button
                      onClick={() => handleDownload(headshot.file_url, index)}
                      className="absolute top-2 right-2 p-2 bg-white rounded-full shadow-md hover:bg-gray-100"
                      title="Download Image"
                    >
                      <FaDownload className="w-5 h-5 text-gray-700" />
                    </button> */}
                  </div>
                  <div className="text-sm text-red-600">
                    Image expires on {format(addMonths(new Date(headshot.created_at), 1), 'MMM dd, yyyy')}
                  </div>
                </div>
              ))}
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default HeadshotGenerator;