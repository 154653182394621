import React from 'react';

function TermsOfService() {
  return (
    <div className="max-w-7xl mx-auto py-12 px-4 sm:px-6 lg:px-8">
      <h1 className="text-3xl font-bold mb-8">Terms of Service</h1>
      
      <div className="prose max-w-none">
        <h2 className="text-2xl font-semibold mt-8 mb-4">1. Acceptance of Terms</h2>
        <p className="mb-4">
          By accessing and using Career Boost AI, you accept and agree to be bound by these Terms of Service.
        </p>

        <h2 className="text-2xl font-semibold mt-8 mb-4">2. Use of Services</h2>
        <p className="mb-4">
          Our services are provided for personal and professional development purposes. You agree to:
        </p>
        <ul className="list-disc pl-8 mb-4">
          <li>Provide accurate information</li>
          <li>Maintain the security of your account</li>
          <li>Not misuse or abuse the service</li>
          <li>Comply with all applicable laws and regulations</li>
        </ul>

        <h2 className="text-2xl font-semibold mt-8 mb-4">3. Subscription and Payments</h2>
        <p className="mb-4">
          Some features require a paid subscription. You agree to pay all fees according to your selected plan.
        </p>

        <h2 className="text-2xl font-semibold mt-8 mb-4">4. Content Generation</h2>
        <p className="mb-4">
          While we strive to provide high-quality AI-generated content, you are responsible for reviewing and verifying all generated content before use.
        </p>

        <h2 className="text-2xl font-semibold mt-8 mb-4">5. Limitation of Liability</h2>
        <p className="mb-4">
          We are not liable for any indirect, incidental, special, consequential, or punitive damages resulting from your use of our services.
        </p>

        <h2 className="text-2xl font-semibold mt-8 mb-4">6. Changes to Terms</h2>
        <p className="mb-4">
          We reserve the right to modify these terms at any time. Continued use of the service constitutes acceptance of modified terms.
        </p>

        <h2 className="text-2xl font-semibold mt-8 mb-4">7. Customer Service</h2>
        <p className="mb-4">
          For any questions or concerns, you can reach our customer service team at:
        </p>
        <ul className="list-disc pl-8 mb-4">
          <li>Email: support@careerboostai.com</li>
          <li>Contact Form: Available on our website</li>
          <li>Response Time: Within 24-48 business hours</li>
        </ul>

        <h2 className="text-2xl font-semibold mt-8 mb-4">8. Subscription and Billing</h2>
        <p className="mb-4">
          All subscriptions are billed in advance on a monthly basis. You can cancel your subscription at any time through your account settings or by contacting customer service.
        </p>

        <h2 className="text-2xl font-semibold mt-8 mb-4">9. Refund Policy</h2>
        <p className="mb-4">
          We offer a 14-day money-back guarantee on all new subscriptions. To request a refund:
        </p>
        <ul className="list-disc pl-8 mb-4">
          <li>Contact our support team within 14 days of your initial purchase</li>
          <li>Provide your account email and reason for refund</li>
          <li>Refunds will be processed to the original payment method</li>
          <li>Processing time: 5-10 business days</li>
        </ul>

        <h2 className="text-2xl font-semibold mt-8 mb-4">10. Promotional Terms</h2>
        <p className="mb-4">
          Any promotional offers or trial periods:
        </p>
        <ul className="list-disc pl-8 mb-4">
          <li>Are valid for new users only, unless otherwise specified</li>
          <li>Cannot be combined with other offers</li>
          <li>May require a valid payment method to activate</li>
          <li>Will automatically convert to a paid subscription unless cancelled before the end of the trial period</li>
        </ul>

        <h2 className="text-2xl font-semibold mt-8 mb-4">11. Dispute Resolution</h2>
        <p className="mb-4">
          In case of any disputes:
        </p>
        <ul className="list-disc pl-8 mb-4">
          <li>Contact our support team first to resolve the issue</li>
          <li>If unresolved, disputes will be handled through arbitration</li>
          <li>The arbitration will be conducted in [Your jurisdiction]</li>
        </ul>
      </div>
    </div>
  );
}

export default TermsOfService; 