import React, { createContext, useContext, useState } from 'react';
import config from '../config';
import { loadStripe } from '@stripe/stripe-js';

const StripeContext = createContext(null);

export const StripeProvider = ({ children }) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const stripePromise = loadStripe(config.stripeKey);

  const redirectToCheckout = async (priceId, isHeadshotCredit = false) => {
    setLoading(true);
    setError(null);
    try {
      const stripe = await stripePromise;
      
      // Create a Checkout Session
      const response = await fetch('/api/create-checkout-session', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ priceId, isHeadshotCredit }),
      });

      const session = await response.json();

      // Redirect to Checkout
      const result = await stripe.redirectToCheckout({
        sessionId: session.id,
      });

      if (result.error) {
        throw new Error(result.error.message);
      }
    } catch (err) {
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };

  const cancelSubscription = async () => {
    setLoading(true);
    setError(null);
    try {
      const response = await fetch('/api/cancel-subscription', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
      });
      
      if (!response.ok) {
        throw new Error('Failed to cancel subscription');
      }
      
      return { success: true };
    } catch (err) {
      setError(err.message);
      return { success: false, error: err.message };
    } finally {
      setLoading(false);
    }
  };

  const value = {
    loading,
    error,
    redirectToCheckout,
    cancelSubscription,
  };

  return <StripeContext.Provider value={value}>{children}</StripeContext.Provider>;
};

export const useStripe = () => {
  const context = useContext(StripeContext);
  if (!context) {
    throw new Error('useStripe must be used within a StripeProvider');
  }
  return context;
};
