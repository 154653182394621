import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useAuth } from '../contexts/AuthContext';
import ProtectedSubmit from '../components/ProtectedSubmit';
import { Link } from 'react-router-dom';
import ReactMarkdown from 'react-markdown';
import { format, isPast, parseISO } from 'date-fns';

function InterviewPrep() {
  const { user } = useAuth();
  const [title, setTitle] = useState('');
  const [jobDescription, setJobDescription] = useState('');
  const [sessionId, setSessionId] = useState(null);
  const [questions, setQuestions] = useState([]);
  const [answers, setAnswers] = useState({});
  const [currentQuestionId, setCurrentQuestionId] = useState(null);
  const [userAnswer, setUserAnswer] = useState('');
  const [feedback, setFeedback] = useState('');
  const [error, setError] = useState('');
  const [pastPreps, setPastPreps] = useState([]);
  const [resume, setResume] = useState(null);
  const [companyName, setCompanyName] = useState('');
  const [interviewDate, setInterviewDate] = useState('');
  const [responseCount, setResponseCount] = useState({});
  const [searchTerm, setSearchTerm] = useState('');
  const [visiblePreps, setVisiblePreps] = useState({});
  const [customDirections, setCustomDirections] = useState('');
  const [sortOption, setSortOption] = useState('upcoming');
  const [isGenerating, setIsGenerating] = useState(false);
  const [isSubmittingAnswer, setIsSubmittingAnswer] = useState({});
  const [editingDate, setEditingDate] = useState(null);
  const [newInterviewDate, setNewInterviewDate] = useState('');
  const [answeredQuestions, setAnsweredQuestions] = useState(new Set());

  useEffect(() => {
    fetchPastPreps();
    fetchResume();
  }, [user]);

  useEffect(() => {
    if (pastPreps) {
      const answered = new Set();
      pastPreps.forEach(prep => {
        prep.questions?.forEach(question => {
          if (question.responses?.length > 0) {
            answered.add(question.id);
          }
        });
      });
      setAnsweredQuestions(answered);
    }
  }, [pastPreps]);

  const fetchPastPreps = async () => {
    try {
      const response = await axios.get('/api/past-interview-preps', { withCredentials: true });
      if (response.data.pastPreps) {
        setPastPreps(response.data.pastPreps.map(prep => ({
          ...prep,
          questions: prep.questions ? prep.questions.map(q => ({
            ...q,
            responses: q.responses || []
          })) : []
        })));
      } else {
        setError('No past interview preps found.');
      }
    } catch (error) {
      console.error('Error fetching past preps:', error);
    }
  };

  const fetchResume = async () => {
    try {
      const response = await axios.get('/api/user-resume', { withCredentials: true });
      if (response.data.resume) {
        setResume(response.data.fileName);
      }
    } catch (error) {
      console.error('Error fetching resume:', error);
    }
  };

  const handleGenerateQuestions = async (e) => {
    e.preventDefault();
    setIsGenerating(true);
    try {
      const response = await axios.post('/api/practice-sessions', {
        title,
        jobDescription,
        companyName,
        interviewDate,
        customDirections
      }, { withCredentials: true });

      setSessionId(response.data.sessionId);
      setQuestions(response.data.questions);
    } catch (error) {
      setError(error.response?.data?.message || 'Error generating questions. You may have reached your usage limit for your plan.');
    } finally {
      setIsGenerating(false);
    }
  };

  const handleSubmitAnswer = async (questionId, answer) => {
    try {
      setIsSubmittingAnswer(prev => ({ ...prev, [questionId]: true }));
      
      if (answeredQuestions.has(questionId)) {
        setError('This question has already been answered');
        return;
      }

      const response = await axios.post(
        `/api/practice-sessions/${questionId}/answer`,
        { answer },
        { withCredentials: true }
      );

      setAnswers(prev => ({
        ...prev,
        [questionId]: {
          answer,
          feedback: response.data.feedback
        }
      }));

      setAnsweredQuestions(prev => new Set([...prev, questionId]));

    } catch (error) {
      setError('Error submitting answer');
    } finally {
      setIsSubmittingAnswer(prev => ({ ...prev, [questionId]: false }));
    }
  };

  const getRemainingAttempts = (questionId) => {
    return 1 - (responseCount[questionId] || 0);
  };

  const togglePrepVisibility = (index) => {
    setVisiblePreps(prev => ({
      ...prev,
      [index]: !prev[index]
    }));
  };

  const filteredPreps = pastPreps.filter(prep =>
    prep.title.toLowerCase().includes(searchTerm.toLowerCase()) ||
    prep.company_name.toLowerCase().includes(searchTerm.toLowerCase()) ||
    prep.questions.some(question => question.question.toLowerCase().includes(searchTerm.toLowerCase())) ||
    prep.questions.some(question => question.responses.some(response => response.answer.toLowerCase().includes(searchTerm.toLowerCase())))
  ).sort((a, b) => new Date(b.created_at) - new Date(a.created_at));

  const getSortedPreps = () => {
    if (!pastPreps) return [];

    const currentDate = new Date();
    const upcomingPreps = pastPreps.filter(prep =>
      prep.interview_date && !isPast(parseISO(prep.interview_date))
    );
    const pastInterviews = pastPreps.filter(prep =>
      !prep.interview_date || isPast(parseISO(prep.interview_date))
    );

    const sortPreps = (preps) => {
      switch (sortOption) {
        case 'recent':
          return [...preps].sort((a, b) =>
            new Date(b.created_at) - new Date(a.created_at)
          );
        case 'oldest':
          return [...preps].sort((a, b) =>
            new Date(a.created_at) - new Date(b.created_at)
          );
        case 'upcoming':
        default:
          return [...preps].sort((a, b) =>
            new Date(a.interview_date || '9999-12-31') - new Date(b.interview_date || '9999-12-31')
          );
      }
    };

    return {
      upcoming: sortPreps(upcomingPreps),
      past: sortPreps(pastInterviews)
    };
  };

  const handleUpdateInterviewDate = async (sessionId) => {
    try {
      await axios.put(`/api/practice-sessions/${sessionId}/update-date`, {
        interviewDate: newInterviewDate
      }, { withCredentials: true });
      
      setPastPreps(pastPreps.map(prep => 
        prep.id === sessionId 
          ? { ...prep, interview_date: newInterviewDate }
          : prep
      ));
      
      setEditingDate(null);
      setNewInterviewDate('');
    } catch (error) {
      setError('Error updating interview date');
    }
  };

  const renderQuestionSection = (question, qIndex) => {
    const isAnswered = answeredQuestions.has(question.id) || question.responses?.length > 0;
    
    return (
      <div key={qIndex} className="bg-gray-50 rounded-lg p-4">
        <h4 className="font-medium text-gray-900 mb-2">
          Question {qIndex + 1}:
        </h4>
        <p className="text-gray-700 mb-4">{question.question}</p>

        <div className="mt-4 space-y-4">
          <div>
            <label className="block text-sm font-medium text-gray-700">
              Your Answer
            </label>
            <div className="mt-1">
              <textarea
                rows={4}
                className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border border-gray-300 rounded-md p-2"
                placeholder={isAnswered ? "This question has already been answered" : "Type your answer here..."}
                value={answers[question.id]?.answer || ''}
                onChange={(e) => {
                  setAnswers({
                    ...answers,
                    [question.id]: {
                      ...answers[question.id],
                      answer: e.target.value
                    }
                  });
                }}
                disabled={isAnswered}
              />
            </div>
            <div className="mt-2">
              <ProtectedSubmit
                onSubmit={(e) => {
                  e.preventDefault();
                  handleSubmitAnswer(question.id, answers[question.id]?.answer || '');
                }}
                disabled={!answers[question.id]?.answer || isAnswered}
                isLoading={isSubmittingAnswer[question.id]}
                buttonText="Get Feedback"
              />
            </div>
          </div>

          {/* Show current feedback */}
          {answers[question.id]?.feedback && (
            <div className="mt-4 p-4 bg-indigo-50 rounded-lg">
              <h3 className="text-sm font-medium text-gray-900 mb-2">AI Feedback:</h3>
              <ReactMarkdown className="text-sm text-gray-700 prose max-w-none">
                {answers[question.id].feedback}
              </ReactMarkdown>
            </div>
          )}

          {/* Previous responses and feedback */}
          {question.responses && question.responses.map((response, rIndex) => (
            <div key={rIndex} className="ml-4 mb-4 last:mb-0">
              <div className="bg-white rounded-lg p-4 mb-2">
                <p className="text-sm text-gray-600 mb-1">Your Answer:</p>
                <p className="text-gray-800">{response.answer}</p>
              </div>
              {response.feedback && (
                <div className="bg-indigo-50 rounded-lg p-4">
                  <p className="text-sm text-gray-600 mb-1">AI Feedback:</p>
                  <ReactMarkdown className="text-sm text-gray-700 prose max-w-none">
                    {response.feedback}
                  </ReactMarkdown>
                </div>
              )}
            </div>
          ))}
        </div>
      </div>
    );
  };

  return (
    <div className="max-w-7xl mx-auto py-6 sm:px-6 lg:px-8">
      <div className="px-4 py-6 sm:px-0">
        <h1 className="text-3xl font-bold text-gray-900 mb-8">Interview Prep</h1>

        {/* Main content grid */}
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-8">
          {/* Left column - Question Generation Form */}
          <div>
            <form onSubmit={handleGenerateQuestions} className="space-y-6">
              <div>
                <label htmlFor="title" className="block text-sm font-medium text-gray-700">
                  Title
                </label>
                <div className="mt-1">
                  <input
                    type="text"
                    id="title"
                    name="title"
                    className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border border-gray-300 rounded-md p-2"
                    placeholder="e.g., Software Engineer Interview Prep"
                    value={title}
                    onChange={(e) => setTitle(e.target.value)}
                  />
                </div>
              </div>
              <div>
                <label htmlFor="companyName" className="block text-sm font-medium text-gray-700">
                  Company Name
                </label>
                <div className="mt-1">
                  <input
                    type="text"
                    id="companyName"
                    name="companyName"
                    className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border border-gray-300 rounded-md p-2"
                    placeholder="e.g., Google"
                    value={companyName}
                    onChange={(e) => setCompanyName(e.target.value)}
                  />
                </div>
              </div>
              <div>
                <label htmlFor="interviewDate" className="block text-sm font-medium text-gray-700">
                  Interview Date
                </label>
                <div className="mt-1">
                  <input
                    type="date"
                    id="interviewDate"
                    name="interviewDate"
                    className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border border-gray-300 rounded-md p-2"
                    value={interviewDate}
                    onChange={(e) => setInterviewDate(e.target.value)}
                  />
                </div>
              </div>
              <div>
                <label htmlFor="jobDescription" className="block text-sm font-medium text-gray-700">
                  Job Description
                </label>
                <div className="mt-1">
                  <textarea
                    id="jobDescription"
                    name="jobDescription"
                    rows={6}
                    className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 mt-1 block w-full sm:text-sm border border-gray-300 rounded-md resize-none p-2"
                    placeholder="Enter job description"
                    value={jobDescription}
                    onChange={(e) => setJobDescription(e.target.value)}
                  />
                </div>
              </div>
              <div>
                <label htmlFor="customDirections" className="block text-sm font-medium text-gray-700">
                  Custom Directions (Optional)
                </label>
                <div className="mt-1">
                  <textarea
                    id="customDirections"
                    name="customDirections"
                    rows={3}
                    className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 mt-1 block w-full sm:text-sm border border-gray-300 rounded-md resize-none p-2"
                    placeholder="E.g., Focus on leadership questions, include behavioral questions, make questions more technical, etc."
                    value={customDirections}
                    onChange={(e) => setCustomDirections(e.target.value)}
                  />
                </div>
              </div>
              {user && (
                <div>
                  <label className="block text-sm font-medium text-gray-700">
                    Resume
                  </label>
                  <div className="mt-1">
                    {resume ? (
                      <div className="text-sm text-gray-600">
                        {resume}
                        <Link
                          to="/profile"
                          className="ml-2 text-indigo-600 hover:text-indigo-800"
                        >
                          Update Resume
                        </Link>
                      </div>
                    ) : (
                      <div className="text-sm text-red-600">
                        Please upload a resume in your profile first
                        <Link
                          to="/profile"
                          className="ml-2 text-indigo-600 hover:text-indigo-800"
                        >
                          Go to Profile
                        </Link>
                      </div>
                    )}
                  </div>
                </div>
              )}
              <div>
                <ProtectedSubmit
                  onSubmit={handleGenerateQuestions}
                  buttonText="Generate Questions"
                  isLoading={isGenerating}
                >
                  Generate Questions
                </ProtectedSubmit>
              </div>
            </form>

            {error && (
              <div className="mt-4 text-sm text-red-600 p-3 bg-gray-50 rounded-lg">
                {error}
              </div>
            )}
          </div>

          {/* Right column - Questions and Responses */}
          <div className="lg:pl-8">
            {questions.length > 0 && (
              <div className="space-y-8">
                <h2 className="text-xl font-semibold text-gray-900 mb-4">Practice Questions</h2>
                {questions.map((question, index) => (
                  <div key={index} className="bg-white shadow rounded-lg p-6 space-y-4">
                    <div className="text-sm text-gray-900 font-medium">
                      Question {index + 1}:
                    </div>
                    <div className="text-sm text-gray-700">
                      {question.question}
                    </div>

                    {/* Response section for each question */}
                    <div className="mt-4 space-y-4">
                      <div>
                        <label className="block text-sm font-medium text-gray-700">
                          Your Answer
                        </label>
                        <div className="mt-1">
                          <textarea
                            rows={4}
                            className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border border-gray-300 rounded-md p-2 min-h-[100px]"
                            placeholder="Type your answer here..."
                            value={answers[question.id]?.answer || ''}
                            onChange={(e) => {
                              // Auto-resize the textarea
                              e.target.style.height = 'inherit';
                              e.target.style.height = `${e.target.scrollHeight}px`;
                              
                              setAnswers({
                                ...answers,
                                [question.id]: {
                                  ...answers[question.id],
                                  answer: e.target.value
                                }
                              });
                            }}
                            disabled={responseCount[question.id] >= 5}
                            style={{ resize: 'vertical' }}
                          />
                        </div>
                        <div className="mt-2 flex justify-between items-center">
                          {/* <span className="text-sm text-gray-500">
                            Remaining attempts: {getRemainingAttempts(question.id)}
                          </span> */}
                          <ProtectedSubmit
                            onSubmit={(e) => {
                              e.preventDefault();
                              handleSubmitAnswer(question.id, answers[question.id]?.answer || '');
                            }}
                            disabled={answers[question.id]?.feedback}
                            isLoading={isSubmittingAnswer[question.id]}
                            buttonText="Get Feedback"
                          >
                          </ProtectedSubmit>
                        </div>
                      </div>

                      {/* Feedback section */}
                      {answers[question.id]?.feedback && (
                        <div className="mt-4 p-4 bg-gray-50 rounded-lg">
                          <h3 className="text-sm font-medium text-gray-900 mb-2">Feedback</h3>
                          <ReactMarkdown className="text-sm text-gray-700 prose max-w-none">
                            {answers[question.id].feedback}
                          </ReactMarkdown>
                        </div>
                      )}
                    </div>
                  </div>
                ))}
              </div>
            )}
          </div>
        </div>

        {/* Past Interview Preps section */}
        {pastPreps && pastPreps.length > 0 && (
          <div className="mt-8">
            {/* <div className="flex justify-between items-center mb-4">
              <h2 className="text-xl font-semibold text-gray-900">Interview Preparations</h2>
              <div className="flex items-center space-x-4">
                <label htmlFor="sort" className="text-sm text-gray-600">Sort by:</label>
                <select
                  id="sort"
                  value={sortOption}
                  onChange={(e) => setSortOption(e.target.value)}
                  className="block w-48 pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
                >
                  <option value="upcoming">Upcoming First</option>
                  <option value="recent">Most Recent</option>
                  <option value="oldest">Oldest First</option>
                </select>
              </div>
            </div> */}

            {/* Upcoming Interviews */}
            {getSortedPreps().upcoming.length > 0 && (
              <div className="mb-8">
                <h3 className="text-lg font-medium text-gray-900 mb-4">Upcoming Interviews</h3>
                <div className="space-y-6">
                  {getSortedPreps().upcoming.map((prep, index) => (
                    <div key={index} className="bg-white shadow rounded-lg overflow-hidden">
                      <div 
                        className="px-4 py-5 sm:p-6 cursor-pointer"
                        onClick={() => togglePrepVisibility(`upcoming-${index}`)}
                      >
                        <div className="flex justify-between items-start">
                          <div>
                            <h4 className="text-lg font-medium text-gray-900">{prep.title || 'Untitled Prep'}</h4>
                            {editingDate === prep.id ? (
                              <div className="flex items-center space-x-2">
                                <input
                                  type="date"
                                  value={newInterviewDate}
                                  onChange={(e) => setNewInterviewDate(e.target.value)}
                                  className="mt-1 block w-48 rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                                />
                                <button
                                  onClick={() => handleUpdateInterviewDate(prep.id)}
                                  className="inline-flex items-center px-2.5 py-1.5 border border-transparent text-xs font-medium rounded text-indigo-700 bg-indigo-100 hover:bg-indigo-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                                >
                                  Save
                                </button>
                                <button
                                  onClick={() => setEditingDate(null)}
                                  className="inline-flex items-center px-2.5 py-1.5 border border-transparent text-xs font-medium rounded text-gray-700 bg-gray-100 hover:bg-gray-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500"
                                >
                                  Cancel
                                </button>
                              </div>
                            ) : (
                              <div className="flex items-center space-x-2">
                                <p className="text-sm text-gray-500">
                                  Interview Date: {prep.interview_date ? format(parseISO(prep.interview_date), 'PPP') : 'Not set'}
                                </p>
                                <button
                                  onClick={() => {
                                    setEditingDate(prep.id);
                                    setNewInterviewDate(prep.interview_date || '');
                                  }}
                                  className="text-sm text-indigo-600 hover:text-indigo-800"
                                >
                                  Edit Date
                                </button>
                              </div>
                            )}
                          </div>
                          <div className="flex items-center">
                            <span className="text-sm text-gray-500 mr-2">
                              Created {format(parseISO(prep.created_at), 'PP')}
                            </span>
                            <span className="text-gray-400">
                              {visiblePreps[`upcoming-${index}`] ? '▼' : '▶'}
                            </span>
                          </div>
                        </div>
                      </div>
                      
                      {visiblePreps[`upcoming-${index}`] && (
                        <div className="border-t border-gray-200 px-4 py-5 sm:p-6">
                          {/* Questions and Answers Section */}
                          <div>
                            <dt className="text-lg font-medium text-gray-900 mb-4">Questions and Answers</dt>
                            <dd className="mt-1 text-sm text-gray-900">
                              <div className="space-y-6">
                                {prep.questions && prep.questions.map((question, qIndex) => renderQuestionSection(question, qIndex))}
                              </div>
                            </dd>
                          </div>
                        </div>
                      )}
                    </div>
                  ))}
                </div>
              </div>
            )}

            {/* Past Interviews */}
            {getSortedPreps().past.length > 0 && (
              <div className="mt-8">
                <h3 className="text-lg font-medium text-gray-900 mb-4">Past Interviews</h3>
                <div className="space-y-6">
                  {getSortedPreps().past.map((prep, index) => (
                    <div key={index} className="bg-gray-50 shadow rounded-lg overflow-hidden">
                      <div 
                        className="px-4 py-5 sm:p-6 cursor-pointer"
                        onClick={() => togglePrepVisibility(`past-${index}`)}
                      >
                        <div className="flex justify-between items-start">
                          <div>
                            <h4 className="text-lg font-medium text-gray-900">{prep.title || 'Untitled Prep'}</h4>
                            {editingDate === prep.id ? (
                              <div className="flex items-center space-x-2"
                                   onClick={(e) => e.stopPropagation()} // Prevent accordion toggle when clicking date editor
                              >
                                <input
                                  type="date"
                                  value={newInterviewDate}
                                  onChange={(e) => setNewInterviewDate(e.target.value)}
                                  className="mt-1 block w-48 rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                                />
                                <button
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    handleUpdateInterviewDate(prep.id);
                                  }}
                                  className="inline-flex items-center px-2.5 py-1.5 border border-transparent text-xs font-medium rounded text-indigo-700 bg-indigo-100 hover:bg-indigo-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                                >
                                  Save
                                </button>
                                <button
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    setEditingDate(null);
                                  }}
                                  className="inline-flex items-center px-2.5 py-1.5 border border-transparent text-xs font-medium rounded text-gray-700 bg-gray-100 hover:bg-gray-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500"
                                >
                                  Cancel
                                </button>
                              </div>
                            ) : (
                              <div className="flex items-center space-x-2">
                                <p className="text-sm text-gray-500">
                                  Interview Date: {prep.interview_date ? format(parseISO(prep.interview_date), 'PPP') : 'Not set'}
                                </p>
                                <button
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    setEditingDate(prep.id);
                                    setNewInterviewDate(prep.interview_date || '');
                                  }}
                                  className="text-sm text-indigo-600 hover:text-indigo-800"
                                >
                                  Edit Date
                                </button>
                              </div>
                            )}
                          </div>
                          <div className="flex items-center">
                            <span className="text-sm text-gray-500 mr-2">
                              Created {format(parseISO(prep.created_at), 'PP')}
                            </span>
                            <span className="text-gray-400">
                              {visiblePreps[`past-${index}`] ? '▼' : '▶'}
                            </span>
                          </div>
                        </div>
                      </div>
                      
                      {visiblePreps[`past-${index}`] && (
                        <div className="border-t border-gray-200 px-4 py-5 sm:p-6">
                          <div>
                            <dt className="text-lg font-medium text-gray-900 mb-4">Questions and Answers</dt>
                            <dd className="mt-1 text-sm text-gray-900">
                              <div className="space-y-6">
                                {prep.questions && prep.questions.map((question, qIndex) => renderQuestionSection(question, qIndex))}
                              </div>
                            </dd>
                          </div>
                        </div>
                      )}
                    </div>
                  ))}
                </div>
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
}

export default InterviewPrep;