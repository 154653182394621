import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useStripe } from '../contexts/StripeContext';
import { useAuth } from '../contexts/AuthContext';
import config from '../config';

function Pricing() {
    const { isAuthenticated } = useAuth();
    const { redirectToCheckout, loading, error } = useStripe();
    const navigate = useNavigate();
    const [subscriptionStatus, setSubscriptionStatus] = useState(null);

    useEffect(() => {
        const fetchSubscriptionStatus = async () => {
            if (isAuthenticated) {
                try {
                    const response = await fetch('/api/subscription-status');
                    const data = await response.json();
                    setSubscriptionStatus(data);
                } catch (error) {
                    console.error('Error fetching subscription status:', error);
                }
            }
        };

        fetchSubscriptionStatus();
    }, [isAuthenticated]);

    const handleSubscribe = async (priceId) => {
        if (!isAuthenticated) {
            navigate('/register');
            return;
        }

        await redirectToCheckout(priceId);
    };

    const renderSubscribeButton = (planType, priceId) => {
        if (!isAuthenticated) {
            return (
                <Link to="/register" className="w-full flex items-center justify-center px-5 py-3 border border-transparent text-base font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700">
                    Sign up to Subscribe
                </Link>
            );
        }

        if ((subscriptionStatus && subscriptionStatus.plan_type === planType) || (!subscriptionStatus && planType === 'freemium') || (subscriptionStatus && subscriptionStatus.status === 'canceled' && planType === 'freemium')) {
            return (
                <button disabled className="w-full flex items-center justify-center px-5 py-3 border border-transparent text-base font-medium rounded-md text-white bg-gray-400">
                    Current Plan
                </button>
            );
        }

        return (
            <button
                onClick={() => handleSubscribe(priceId)}
                disabled={loading}
                className="w-full flex items-center justify-center px-5 py-3 border border-transparent text-base font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 disabled:bg-gray-400"
            >
                {loading ? 'Processing...' : `Subscribe to ${planType.charAt(0).toUpperCase() + planType.slice(1)}`}
            </button>
        );
    };

    return (
        <div className="bg-gray-100">
            <div className="pt-12 sm:pt-16 lg:pt-20">
                <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
                    <div className="text-center">
                        <h2 className="text-3xl font-extrabold text-gray-900 sm:text-4xl lg:text-5xl">Simple, transparent pricing</h2>
                        <p className="mt-4 text-xl text-gray-600">Choose the plan that's right for you</p>
                    </div>
                </div>
            </div>
            <div className="mt-8 bg-white pb-16 sm:mt-12 sm:pb-20 lg:pb-28">
                <div className="relative">
                    <div className="absolute inset-0 h-1/2 bg-gray-100"></div>
                    <div className="relative max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
                        <div className="max-w-lg mx-auto rounded-lg shadow-lg overflow-hidden lg:max-w-none lg:flex">
                            <div className="flex-1 bg-white px-6 py-8 lg:p-12">
                                <h3 className="text-2xl font-extrabold text-gray-900 sm:text-3xl">Freemium Plan</h3>
                                <p className="mt-6 text-base text-gray-500">Perfect for trying out our AI-powered career tools.</p>
                                <div className="mt-8">
                                    <div className="flex items-center">
                                        <h4 className="flex-shrink-0 pr-4 bg-white text-sm tracking-wider font-semibold uppercase text-indigo-600">What's included</h4>
                                        <div className="flex-1 border-t-2 border-gray-200"></div>
                                    </div>
                                    <ul className="mt-8 space-y-5 lg:space-y-0 lg:grid lg:grid-cols-2 lg:gap-x-8 lg:gap-y-5">
                                        <li className="flex items-start lg:col-span-1">
                                            <div className="flex-shrink-0">
                                                <svg className="h-5 w-5 text-green-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                                                    <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clipRule="evenodd" />
                                                </svg>
                                            </div>
                                            <p className="ml-3 text-sm text-gray-700">3 cover letter generations</p>
                                        </li>
                                        <li className="flex items-start lg:col-span-1">
                                            <div className="flex-shrink-0">
                                                <svg className="h-5 w-5 text-green-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                                                    <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clipRule="evenodd" />
                                                </svg>
                                            </div>
                                            <p className="ml-3 text-sm text-gray-700">3 interview question sets</p>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div className="py-8 px-6 text-center bg-gray-50 lg:flex-shrink-0 lg:flex lg:flex-col lg:justify-center lg:p-12">
                                <p className="text-lg leading-6 font-medium text-gray-900">Freemium Plan</p>
                                <div className="mt-4 flex items-center justify-center text-5xl font-extrabold text-gray-900">
                                    <span>$0</span>
                                    <span className="ml-3 text-xl font-medium text-gray-500">/month</span>
                                </div>
                                <div className="mt-6">
                                    <div className="rounded-md shadow">
                                        {renderSubscribeButton('freemium', null)}
                                    </div>
                                </div>
                            </div>
                        </div>

                        {/* Basic Plan */}
                        <div className="mt-8 max-w-lg mx-auto rounded-lg shadow-lg overflow-hidden lg:max-w-none lg:flex">
                            <div className="flex-1 bg-white px-6 py-8 lg:p-12">
                                <h3 className="text-2xl font-extrabold text-gray-900 sm:text-3xl">Basic Plan</h3>
                                <p className="mt-6 text-base text-gray-500">For users who need more generations.</p>
                                <div className="mt-8">
                                    <div className="flex items-center">
                                        <h4 className="flex-shrink-0 pr-4 bg-white text-sm tracking-wider font-semibold uppercase text-indigo-600">What's included</h4>
                                        <div className="flex-1 border-t-2 border-gray-200"></div>
                                    </div>
                                    <ul className="mt-8 space-y-5 lg:space-y-0 lg:grid lg:grid-cols-2 lg:gap-x-8 lg:gap-y-5">
                                        <li className="flex items-start lg:col-span-1">
                                            <div className="flex-shrink-0">
                                                <svg className="h-5 w-5 text-green-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                                                    <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clipRule="evenodd" />
                                                </svg>
                                            </div>
                                            <p className="ml-3 text-sm text-gray-700">150 cover letter generations per month</p>
                                        </li>
                                        <li className="flex items-start lg:col-span-1">
                                            <div className="flex-shrink-0">
                                                <svg className="h-5 w-5 text-green-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                                                    <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clipRule="evenodd" />
                                                </svg>
                                            </div>
                                            <p className="ml-3 text-sm text-gray-700">150 interview question sets per month</p>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div className="py-8 px-6 text-center bg-gray-50 lg:flex-shrink-0 lg:flex lg:flex-col lg:justify-center lg:p-12">
                                <p className="text-lg leading-6 font-medium text-gray-900">Basic Plan</p>
                                <div className="mt-4 flex items-center justify-center text-5xl font-extrabold text-gray-900">
                                    <span>$4.99</span>
                                    <span className="ml-3 text-xl font-medium text-gray-500">/month</span>
                                </div>
                                <div className="mt-6">
                                    <div className="rounded-md shadow">
                                        {renderSubscribeButton('basic', config.stripePrices.basic)}
                                    </div>
                                </div>
                            </div>
                        </div>

                        {/* Premium Plan */}
                        <div className="mt-8 max-w-lg mx-auto rounded-lg shadow-lg overflow-hidden lg:max-w-none lg:flex">
                            <div className="flex-1 bg-white px-6 py-8 lg:p-12">
                                <h3 className="text-2xl font-extrabold text-gray-900 sm:text-3xl">Premium Plan</h3>
                                <p className="mt-6 text-base text-gray-500">For professional users trying to advance their careers</p>
                                <div className="mt-8">
                                    <div className="flex items-center">
                                        <h4 className="flex-shrink-0 pr-4 bg-white text-sm tracking-wider font-semibold uppercase text-indigo-600">What's included</h4>
                                        <div className="flex-1 border-t-2 border-gray-200"></div>
                                    </div>
                                    <ul className="mt-8 space-y-5 lg:space-y-0 lg:grid lg:grid-cols-2 lg:gap-x-8 lg:gap-y-5">
                                        <li className="flex items-start lg:col-span-1">
                                            <div className="flex-shrink-0">
                                                <svg className="h-5 w-5 text-green-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                                                    <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clipRule="evenodd" />
                                                </svg>
                                            </div>
                                            <p className="ml-3 text-sm text-gray-700">1000 cover letter generations</p>
                                        </li>
                                        <li className="flex items-start lg:col-span-1">
                                            <div className="flex-shrink-0">
                                                <svg className="h-5 w-5 text-green-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                                                    <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clipRule="evenodd" />
                                                </svg>
                                            </div>
                                            <p className="ml-3 text-sm text-gray-700">1000 interview question sets</p>
                                        </li>
                                        <li className="flex items-start lg:col-span-1">
                                            <div className="flex-shrink-0">
                                                <svg className="h-5 w-5 text-green-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                                                    <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clipRule="evenodd" />
                                                </svg>
                                            </div>
                                            <p className="ml-3 text-sm text-gray-700">Priority support</p>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div className="py-8 px-6 text-center bg-gray-50 lg:flex-shrink-0 lg:flex lg:flex-col lg:justify-center lg:p-12">
                                <p className="text-lg leading-6 font-medium text-gray-900">Premium Plan</p>
                                <div className="mt-4 flex items-center justify-center text-5xl font-extrabold text-gray-900">
                                    <span>$9.99</span>
                                    <span className="ml-3 text-xl font-medium text-gray-500">/month</span>
                                </div>
                                <div className="mt-6">
                                    <div className="rounded-md shadow">
                                        {renderSubscribeButton('premium', config.stripePrices.premium)}
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="mt-12">
                            <div className="bg-white shadow overflow-hidden sm:rounded-lg">
                                <div className="px-4 py-5 sm:px-6">
                                    <h3 className="text-lg leading-6 font-medium text-gray-900">Professional Headshot Generation</h3>
                                    <p className="mt-1 max-w-2xl text-sm text-gray-500">One-time purchase for AI-powered professional headshots</p>
                                </div>
                                <div className="border-t border-gray-200 px-4 py-5 sm:p-6">
                                    <div className="text-center">
                                        <p className="text-3xl font-extrabold text-gray-900">$9.99</p>
                                        <p className="mt-2 text-sm text-gray-500">1 headshot generation credit</p>
                                        <div className="mt-4">
                                            <button
                                                onClick={() => redirectToCheckout(config.stripePrices.headshot, true)}
                                                className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-indigo-600 hover:bg-indigo-700"
                                            >
                                                Purchase Headshots
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {error && (
                <div className="mt-4 text-red-600 text-center">
                    {error}
                </div>
            )}
        </div>
    );
}

export default Pricing;