import React from 'react';

function PrivacyPolicy() {
 return (
   <div className="max-w-7xl mx-auto py-12 px-4 sm:px-6 lg:px-8">
     <h1 className="text-3xl font-bold mb-8">Privacy Policy</h1>
     
     <div className="prose max-w-none">
       <h2 className="text-2xl font-semibold mt-8 mb-4">1. Information We Collect</h2>
       <p className="mb-4">
         We collect information that you provide directly to us, including:
       </p>
       <ul className="list-disc pl-8 mb-4">
         <li>Name and contact information</li>
         <li>Resume content and professional experience</li>
         <li>Job application related information</li>
         <li>Profile photos for headshot generation</li>
       </ul>
        <h2 className="text-2xl font-semibold mt-8 mb-4">2. How We Use Your Information</h2>
       <p className="mb-4">
         We use the information we collect to:
       </p>
       <ul className="list-disc pl-8 mb-4">
         <li>Generate personalized cover letters and resumes</li>
         <li>Create professional headshots</li>
         <li>Provide interview preparation assistance</li>
         <li>Improve our services and develop new features</li>
       </ul>
        <h2 className="text-2xl font-semibold mt-8 mb-4">3. Data Security</h2>
       <p className="mb-4">
         We implement appropriate security measures to protect your personal information. However, no method of transmission over the Internet is 100% secure.
       </p>
        <h2 className="text-2xl font-semibold mt-8 mb-4">4. Data Retention</h2>
       <p className="mb-4">
         We retain your information for as long as your account is active or as needed to provide you services. You can request deletion of your data at any time.
       </p>
        <h2 className="text-2xl font-semibold mt-8 mb-4">5. Contact Us</h2>
       <p className="mb-4">
         If you have any questions about this Privacy Policy, please contact us at support@careerboostai.com
       </p>
     </div>
   </div>
 );
}

export default PrivacyPolicy;