import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Navigation from './components/Navigation';
import LandingPage from './pages/LandingPage';
import Pricing from './pages/Pricing';
import ResumeGenerator from './pages/ResumeGenerator';
import InterviewPrep from './pages/InterviewPrep';
import HeadshotGenerator from './pages/HeadshotGenerator';
import Login from './pages/Login';
import Register from './pages/Register';
import Profile from './pages/Profile';
import { AuthProvider } from './contexts/AuthContext';
import CoverLetterWriter from './pages/CoverLetterWriter';
import PrivacyPolicy from './pages/PrivacyPolicy';
import TermsOfService from './pages/TermsOfService';
import Footer from './components/Footer';
import { StripeProvider } from './contexts/StripeContext';
import ContactUs from './pages/ContactUs';
import ForgotPassword from './pages/ForgotPassword';

function App() {
  return (
    <AuthProvider>
      <StripeProvider>
        <Router>
          <div className="App">
            <Navigation />
          <Routes>
            <Route path="/" element={<LandingPage />} />
            <Route path="/pricing" element={<Pricing />} />
            <Route path="/cover-letter" element={<CoverLetterWriter />} />
            <Route path="/interview-prep" element={<InterviewPrep />} />
            <Route path="/headshot-generator" element={<HeadshotGenerator />} />
            <Route path="/login" element={<Login />} />
            <Route path="/register" element={<Register />} />
            <Route path="/profile" element={<Profile />} />
            <Route path="/privacy-policy" element={<PrivacyPolicy />} />
            <Route path="/terms-of-service" element={<TermsOfService />} />
            <Route path="/contact" element={<ContactUs />} />
            <Route path="/forgot-password" element={<ForgotPassword />} />
          </Routes>
            <Footer />
          </div>
        </Router>
      </StripeProvider>
    </AuthProvider>
  );
}

export default App;